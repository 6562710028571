import React from 'react';

import AboutBanner from 'assets/images/AboutBanner.png';

import styles from './index.module.scss';

function AboutUs() {
  return (
    <div className={styles.Container}>
      <div className={styles.TextContainer}>
        <div className={styles.Subtitle}>Get to Know</div>

        <div className={styles.Title}>ABOUT US</div>

        <div className={styles.Paragraph}>
          We were orignally a law firm in Vietnam
          focusing on startups and SMEs support.
          Since 2019, we have transitioned to provide regular
          compliances consultation for firms in the FinTech
          and Blockchain space,
          as well as M&A within these two industries;
          allowing firms to grow and expand rapidly without
          facing any legal bottlenecks and constraints.
        </div>

        <div className={styles.SecondParagraph}>
          In 2021,
          we has resolved 20 different cases of Blockchain
          courts and decided to run another branch called
          Legal3 where we can 100% focus on our mission to
          protect investors and providing startups with
          the tools to propel to new heights.
        </div>
      </div>

      <div className={styles.ImageContainer}>
        <img src={AboutBanner} alt="banner" className={styles.Image} />
      </div>
    </div>
  );
}

export default AboutUs;
