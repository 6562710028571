import {
  GET_ALL_NEWS,
  GET_ALL_NEWS_SUCCESS,
  GET_ALL_NEWS_FAILED,

  GET_LATEST_HIGHLIGHT_NEW,
  GET_LATEST_HIGHLIGHT_NEW_SUCCESS,
  GET_LATEST_HIGHLIGHT_NEW_FAILED,

  GET_PREVIOUS_NEWS,
  GET_PREVIOUS_NEWS_SUCCESS,
  GET_PREVIOUS_NEWS_FAILED,

  GET_NEXT_NEWS,
  GET_NEXT_NEWS_SUCCESS,
  GET_NEXT_NEWS_FAILED,

  GET_RELATED_NEWS,
  GET_RELATED_NEWS_SUCCESS,
  GET_RELATED_NEWS_FAILED,

  GET_ALL_NEWS_IN_MONTH,
  GET_ALL_NEWS_IN_MONTH_SUCCESS,
  GET_ALL_NEWS_IN_MONTH_FAILED,
} from './actionTypes';

export const actionGetAllNews = (payload) => ({
  type: GET_ALL_NEWS,
  payload,
});

export const actionGetAllNewsSuccess = (payload) => ({
  type: GET_ALL_NEWS_SUCCESS,
  payload,
});

export const actionGetAllNewsFailed = () => ({
  type: GET_ALL_NEWS_FAILED,
});

export const actionGetLatestHighlightNew = (payload) => ({
  type: GET_LATEST_HIGHLIGHT_NEW,
  payload,
});

export const actionGetLatestHighlightNewSuccess = (payload) => ({
  type: GET_LATEST_HIGHLIGHT_NEW_SUCCESS,
  payload,
});

export const actionGetLatestHighlightNewFailed = () => ({
  type: GET_LATEST_HIGHLIGHT_NEW_FAILED,
});

export const actionGetPreviousNews = (payload) => ({
  type: GET_PREVIOUS_NEWS,
  payload,
});

export const actionGetPreviousNewsSuccess = (payload) => ({
  type: GET_PREVIOUS_NEWS_SUCCESS,
  payload,
});

export const actionGetPreviousNewsFailed = () => ({
  type: GET_PREVIOUS_NEWS_FAILED,
});

export const actionGetNextNews = (payload) => ({
  type: GET_NEXT_NEWS,
  payload,
});

export const actionGetNextNewsSuccess = (payload) => ({
  type: GET_NEXT_NEWS_SUCCESS,
  payload,
});

export const actionGetNextNewsFailed = () => ({
  type: GET_NEXT_NEWS_FAILED,
});

export const actionGetRelatedNews = (payload) => ({
  type: GET_RELATED_NEWS,
  payload,
});

export const actionGetRelatedNewsSuccess = (payload) => ({
  type: GET_RELATED_NEWS_SUCCESS,
  payload,
});

export const actionGetRelatedNewsFailed = () => ({
  type: GET_RELATED_NEWS_FAILED,
});

export const actionGetAllNewsInMonth = (payload) => ({
  type: GET_ALL_NEWS_IN_MONTH,
  payload,
});

export const actionGetAllNewsInMonthSuccess = (payload) => ({
  type: GET_ALL_NEWS_IN_MONTH_SUCCESS,
  payload,
});

export const actionGetAllNewsInMonthFailed = () => ({
  type: GET_ALL_NEWS_IN_MONTH_FAILED,
});
