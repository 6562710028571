const LOCATIONS = {
  HOME: '/',
  ABOUT: 'about',
  CASESTUDY: 'casestudy',
  CONTACT: 'contact',
  SERVICES: 'services',
  BLOG: 'blog',
  NEW: 'new',
};

// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:+\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_REGEX = /^\d{10,12}$/;

export const TEXT_LENGTH_LIMIT = {
  VERY_SHORT: 25,
  SHORT: 150,
  MEDIUM: 300,
  LONG: 2500,
  PASSWORD: 6,
};

export default LOCATIONS;
