import React from 'react';

import { motion } from 'framer-motion';

import HomeBackground from 'assets/images/HomeBackground.png';
import { ReactComponent as Shield } from 'assets/images/Shield.svg';
import { ReactComponent as ArrowToRight } from 'assets/icons/ArrowToRight.svg';

import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';

const shieldMotion = {
  initial: { top: '0px', left: 'calc(80% - 200px)' },
  animate: { left: '462px', top: '265px' },
  exit: { top: '0px', left: 'calc(80% - 100px)' },
};

function HomeTop() {
  const navigate = useNavigate();

  const handleToServices = () => {
    navigate('/services');
  };

  return (
    <div className={styles.HomeTop}>
      <div className={styles.InnerContainer}>
        <div className={styles.ImageContainer}>
          <img src={HomeBackground} alt="Home" className={styles.HomeBackground} />

          <motion.div
            key="shield"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={shieldMotion}
            transition={{ duration: 0.5 }}
            className={styles.Shield}
          >
            <Shield />
          </motion.div>
        </div>

        <div className={styles.HomeRight}>
          <div className={styles.Title}>
            YOU RUN YOUR
            <br />
            BUSINESS
          </div>

          <div className={styles.Subtitle}>We protect it</div>

          <button type="button" className={styles.ToServiceButton} onClick={handleToServices}>
            Our Services
            <ArrowToRight />
          </button>
        </div>
      </div>
    </div>
  );
}

export default HomeTop;
