import {
  GET_ALL_NEWS,
  GET_ALL_NEWS_SUCCESS,
  GET_ALL_NEWS_FAILED,

  GET_LATEST_HIGHLIGHT_NEW,
  GET_LATEST_HIGHLIGHT_NEW_SUCCESS,
  GET_LATEST_HIGHLIGHT_NEW_FAILED,

  GET_PREVIOUS_NEWS,
  GET_PREVIOUS_NEWS_SUCCESS,
  GET_PREVIOUS_NEWS_FAILED,

  GET_NEXT_NEWS,
  GET_NEXT_NEWS_SUCCESS,
  GET_NEXT_NEWS_FAILED,

  GET_RELATED_NEWS,
  GET_RELATED_NEWS_SUCCESS,
  GET_RELATED_NEWS_FAILED,

  GET_ALL_NEWS_IN_MONTH,
  GET_ALL_NEWS_IN_MONTH_SUCCESS,
  GET_ALL_NEWS_IN_MONTH_FAILED,
} from './actionTypes';

const initialState = {
  loading: false,
  highlightedNew: null,
  randomNews: [],
  news: [],
  relatedNews: [],
};

const NewsReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_NEWS:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_NEWS_SUCCESS:
    {
      const { newsConnection } = action.payload;
      if (newsConnection && newsConnection.edges && newsConnection.edges.length > 0) {
        const { edges } = newsConnection;
        return {
          ...state,
          news: edges.slice(0, 5).map((edge) => edge.node),
          randomNews: edges.slice(-2).map((edge) => edge.node),
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
      };
    }

    case GET_ALL_NEWS_FAILED:
      return {
        ...state,
        loading: false,
        news: initialState.news,
      };

    case GET_ALL_NEWS_IN_MONTH:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_NEWS_IN_MONTH_SUCCESS:
    {
      const { newsConnection } = action.payload;
      if (newsConnection && newsConnection.edges && newsConnection.edges.length > 0) {
        const { edges } = newsConnection;
        return {
          ...state,
          news: edges.slice(0, 5).map((edge) => edge.node),
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
      };
    }

    case GET_ALL_NEWS_IN_MONTH_FAILED:
      return {
        ...state,
        loading: false,
        news: initialState.news,
      };

    case GET_LATEST_HIGHLIGHT_NEW:
      return {
        ...state,
        loading: true,
      };

    case GET_LATEST_HIGHLIGHT_NEW_SUCCESS:
    {
      const { newsConnection } = action.payload;
      if (newsConnection && newsConnection.edges && newsConnection.edges.length > 0) {
        const { edges } = newsConnection;
        return {
          ...state,
          highlightedNew: edges[0].node,
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
      };
    }

    case GET_LATEST_HIGHLIGHT_NEW_FAILED:
      return {
        ...state,
        loading: false,
        highlightedNew: initialState.highlightedNew,
      };

    case GET_NEXT_NEWS:
      return {
        ...state,
        loading: true,
      };

    case GET_NEXT_NEWS_SUCCESS:
    {
      const { newsConnection } = action.payload;
      if (newsConnection && newsConnection.edges && newsConnection.edges.length > 0) {
        const { edges } = newsConnection;
        return {
          ...state,
          news: edges.map((edge) => edge.node),
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
      };
    }

    case GET_NEXT_NEWS_FAILED:
      return {
        ...state,
        loading: false,
        news: initialState.news,
      };

    case GET_PREVIOUS_NEWS:
      return {
        ...state,
        loading: true,
      };

    case GET_PREVIOUS_NEWS_SUCCESS:
    {
      const { newsConnection } = action.payload;
      if (newsConnection && newsConnection.edges && newsConnection.edges.length > 0) {
        const { edges } = newsConnection;
        return {
          ...state,
          news: edges.map((edge) => edge.node),
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
      };
    }

    case GET_PREVIOUS_NEWS_FAILED:
      return {
        ...state,
        loading: false,
        news: initialState.news,
      };

    case GET_RELATED_NEWS:
      return {
        ...state,
        loading: true,
      };

    case GET_RELATED_NEWS_SUCCESS:
    {
      const { news } = action.payload;
      return {
        ...state,
        relatedNews: news,
        loading: false,
      };
    }

    case GET_RELATED_NEWS_FAILED:
      return {
        ...state,
        loading: false,
        relatedNews: initialState.relatedNews,
      };

    default:
      return state;
  }
};

export default NewsReducers;
