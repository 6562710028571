export const TextReduceHelper = ({ text, maxLength }) => {
  const reducedText = text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  return reducedText;
};

export const addOneMonth = (dateString) => {
  const [year, month, day] = dateString.split('-').map(Number);

  const date = new Date(year, month - 1, day);

  // Add one month
  date.setMonth(date.getMonth() + 1);

  const newYear = date.getFullYear();
  const newMonth = date.getMonth() + 1;
  const newDay = date.getDate();

  const newDateString = `${newYear}-${newMonth.toString().padStart(2, '0')}-${newDay.toString().padStart(2, '0')}`;

  return newDateString;
};
