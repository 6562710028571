import {
  GET_ALL_BLOGS,
  GET_ALL_BLOGS_SUCCESS,
  GET_ALL_BLOGS_FAILED,

  GET_LATEST_HIGHLIGHT_BLOG,
  GET_LATEST_HIGHLIGHT_BLOG_SUCCESS,
  GET_LATEST_HIGHLIGHT_BLOG_FAILED,

  GET_PREVIOUS_BLOGS,
  GET_PREVIOUS_BLOGS_SUCCESS,
  GET_PREVIOUS_BLOGS_FAILED,

  GET_NEXT_BLOGS,
  GET_NEXT_BLOGS_SUCCESS,
  GET_NEXT_BLOGS_FAILED,

  GET_RELATED_BLOGS,
  GET_RELATED_BLOGS_SUCCESS,
  GET_RELATED_BLOGS_FAILED,

  GET_ALL_BLOGS_IN_MONTH,
  GET_ALL_BLOGS_IN_MONTH_SUCCESS,
  GET_ALL_BLOGS_IN_MONTH_FAILED,
} from './actionTypes';

export const actionGetAllBlogs = (payload) => ({
  type: GET_ALL_BLOGS,
  payload,
});

export const actionGetAllBlogsSuccess = (payload) => ({
  type: GET_ALL_BLOGS_SUCCESS,
  payload,
});

export const actionGetAllBlogsFailed = () => ({
  type: GET_ALL_BLOGS_FAILED,
});

export const actionGetLatestHighlightBlog = (payload) => ({
  type: GET_LATEST_HIGHLIGHT_BLOG,
  payload,
});

export const actionGetLatestHighlightBlogSuccess = (payload) => ({
  type: GET_LATEST_HIGHLIGHT_BLOG_SUCCESS,
  payload,
});

export const actionGetLatestHighlightBlogFailed = () => ({
  type: GET_LATEST_HIGHLIGHT_BLOG_FAILED,
});

export const actionGetPreviousBlogs = (payload) => ({
  type: GET_PREVIOUS_BLOGS,
  payload,
});

export const actionGetPreviousBlogsSuccess = (payload) => ({
  type: GET_PREVIOUS_BLOGS_SUCCESS,
  payload,
});

export const actionGetPreviousBlogsFailed = () => ({
  type: GET_PREVIOUS_BLOGS_FAILED,
});

export const actionGetNextBlogs = (payload) => ({
  type: GET_NEXT_BLOGS,
  payload,
});

export const actionGetNextBlogsSuccess = (payload) => ({
  type: GET_NEXT_BLOGS_SUCCESS,
  payload,
});

export const actionGetNextBlogsFailed = () => ({
  type: GET_NEXT_BLOGS_FAILED,
});

export const actionGetRelatedBlogs = (payload) => ({
  type: GET_RELATED_BLOGS,
  payload,
});

export const actionGetRelatedBlogsSuccess = (payload) => ({
  type: GET_RELATED_BLOGS_SUCCESS,
  payload,
});

export const actionGetRelatedBlogsFailed = () => ({
  type: GET_RELATED_BLOGS_FAILED,
});

export const actionGetAllBlogsInMonth = (payload) => ({
  type: GET_ALL_BLOGS_IN_MONTH,
  payload,
});

export const actionGetAllBlogsInMonthSuccess = (payload) => ({
  type: GET_ALL_BLOGS_IN_MONTH_SUCCESS,
  payload,
});

export const actionGetAllBlogsInMonthFailed = () => ({
  type: GET_ALL_BLOGS_IN_MONTH_FAILED,
});
