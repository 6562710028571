import React from 'react';

import PropTypes from 'prop-types';

import styles from './index.module.scss';

function InfoMember({ image, name, title }) {
  return (
    <div className={styles.Container}>
      <div className={styles.AvatarContainer}>
        <img src={image} alt={name} />
        <span className={styles.TopLeft} />
        <span className={styles.TopRight} />
        <span className={styles.BottomLeft} />
        <span className={styles.BottomRight} />
      </div>

      <div className={styles.Name}>{name}</div>

      <div className={styles.Title}>{title}</div>
    </div>
  );
}

InfoMember.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default InfoMember;
