import { combineReducers } from 'redux';

import Blogs from 'store/blogs/reducer';
import Blog from 'store/blog/reducer';
import News from 'store/news/reducer';
import New from 'store/new/reducer';
import Cases from 'store/cases/reducer';
import Case from 'store/case/reducer';
import Feedback from 'store/feedback/reducer';

const rootReducer = combineReducers({
  Blogs,
  Blog,

  News,
  New,

  Cases,
  Case,

  Feedback,
});

export default rootReducer;
