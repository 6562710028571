import { put, call, takeLeading } from 'redux-saga/effects';
import { request } from 'graphql-request';

import {
  GET_ALL_CASES,
  GET_RELATED_CASES,
} from './actionTypes';

import {
  actionGetAllCasesSuccess,
  actionGetAllCasesFailed,

  actionGetRelatedCasesSuccess,
  actionGetRelatedCasesFailed,
} from './actions';

const ALL_CASES_QUERY = `
  query MyQuery {
    casesConnection {
      edges {
        node {
          coverImage {
            url
          }
          slug
          title
        }
      }
    }
  }
`;

const RELATED_QUERY = `
query MyQuery {
  cases(first: 3, where: { tags_contains_some: ["Scam"] }) {
    slug
    title
    coverImage {
      url
    }
  }
  fallbackItems: cases(first: 3, where: { tags_contains_none: ["Scam"] }) {
    slug
    title
    coverImage {
      url
    }
  }
}
`;

function* getAllCases() {
  try {
    const data = yield call(request, process.env.REACT_APP_GRAPH_ENDPOINT, ALL_CASES_QUERY);

    yield put(actionGetAllCasesSuccess(data));
  } catch (error) {
    yield put(actionGetAllCasesFailed());
  }
}

function* getRelatedCases(action) {
  try {
    const { tags } = action.payload;
    const variables = { tags };
    const response = yield call(
      request,
      process.env.REACT_APP_GRAPH_ENDPOINT,

      RELATED_QUERY,

      variables,
    );

    yield put(actionGetRelatedCasesSuccess(response));
  } catch (error) {
    yield put(actionGetRelatedCasesFailed());
  }
}

export default function* CasesSaga() {
  yield takeLeading(GET_ALL_CASES, getAllCases);
  yield takeLeading(GET_RELATED_CASES, getRelatedCases);
}
