import { put, call, takeLeading } from 'redux-saga/effects';
import { request } from 'graphql-request';

import { addOneMonth } from 'utils';

import {
  GET_ALL_NEWS,
  GET_LATEST_HIGHLIGHT_NEW,
  GET_NEXT_NEWS,
  GET_PREVIOUS_NEWS,
  GET_RELATED_NEWS,
  GET_ALL_NEWS_IN_MONTH,
} from './actionTypes';

import {
  actionGetAllNewsSuccess,
  actionGetAllNewsFailed,

  actionGetLatestHighlightNewSuccess,
  actionGetLatestHighlightNewFailed,

  actionGetNextNewsSuccess,
  actionGetNextNewsFailed,

  actionGetPreviousNewsSuccess,
  actionGetPreviousNewsFailed,

  actionGetRelatedNewsSuccess,
  actionGetRelatedNewsFailed,

  actionGetAllNewsInMonthSuccess,
  actionGetAllNewsInMonthFailed,
} from './actions';

const ALL_NEWS_QUERY = `
  query MyQuery {
    newsConnection(where: { isHighlight: false }, orderBy: date_DESC, first: 7) {
      edges {
        node {
          coverImage {
            url
          }
          date
          excerpt
          slug
          title
          content {
            html
          }
          author {
            name
          }
          isHighlight
        }
      }
    }
  }
`;

const HIGHLIGHT_QUERY = `
  query MyQuery {
    newsConnection(where: { isHighlight: true }, orderBy: date_ASC, first: 1) {
      edges {
        node {
          coverImage {
            url
          }
          date
          excerpt
          slug
          title
          content {
            html
          }
          author {
            name
          }
          isHighlight
        }
      }
    }
  }
`;

const NEXT_NEWS_QUERY = `
  query MyQuery($cursor: Date!) {
    newsConnection(where: { isHighlight: false, date_gt: $cursor }, orderBy: date_DESC, first: 5) {
      edges {
        node {
          coverImage {
            url
          }
          date
          excerpt
          slug
          title
          content {
            html
          }
          author {
            name
          }
          isHighlight
        }
      }
    }
  }
`;

const PREVIOUS_NEWS_QUERY = `
  query MyQuery($cursor: Date!) {
    newsConnection(where: { isHighlight: false, date_lt: $cursor }, orderBy: date_DESC, first: 5) {
      edges {
        node {
          coverImage {
            url
          }
          date
          excerpt
          slug
          title
          content {
            html
          }
          author {
            name
          }
          isHighlight
        }
      }
    }
  }
`;

const RELATED_QUERY = `
query MyQuery($tags: [String!]) {
  news(where: { tags_contains_some: $tags }) {
    coverImage {
      url
    }
    title
    slug
  }
}
`;

const ALL_NEWS_IN_MONTH_QUERY = `
  query MyQuery($startDate: Date!, $endDate: Date!) {
    newsConnection(
      where: { isHighlight: false, date_gte: $startDate, date_lt: $endDate }
      orderBy: date_DESC
    ) {
      edges {
        node {
          coverImage {
            url
          }
          date
          excerpt
          slug
          title
          content {
            html
          }
          author {
            name
          }
          isHighlight
        }
      }
    }
  }
`;

function* getAllNews() {
  try {
    const data = yield call(request, process.env.REACT_APP_GRAPH_ENDPOINT, ALL_NEWS_QUERY);

    yield put(actionGetAllNewsSuccess(data));
  } catch (error) {
    yield put(actionGetAllNewsFailed());
  }
}

function* getAllNewsInMonth(action) {
  const { selectedDate } = action.payload;

  try {
    const startDate = selectedDate;
    const endDate = addOneMonth(selectedDate);

    const variables = {
      startDate,
      endDate,
    };

    const data = yield call(
      request,
      process.env.REACT_APP_GRAPH_ENDPOINT,
      ALL_NEWS_IN_MONTH_QUERY,
      variables,
    );

    yield put(actionGetAllNewsInMonthSuccess(data));
  } catch (error) {
    yield put(actionGetAllNewsInMonthFailed());
  }
}

function* getLatestHighlightNew() {
  try {
    const data = yield call(request, process.env.REACT_APP_GRAPH_ENDPOINT, HIGHLIGHT_QUERY);

    yield put(actionGetLatestHighlightNewSuccess(data));
  } catch (error) {
    yield put(actionGetLatestHighlightNewFailed());
  }
}

function* getNextNews(action) {
  try {
    const { cursor } = action.payload;
    const data = yield call(
      request,
      process.env.REACT_APP_GRAPH_ENDPOINT,
      NEXT_NEWS_QUERY,
      { cursor },
    );

    yield put(actionGetNextNewsSuccess(data));
  } catch (error) {
    yield put(actionGetNextNewsFailed());
  }
}

function* getPreviousNews(action) {
  try {
    const { cursor } = action.payload;
    const data = yield call(
      request,
      process.env.REACT_APP_GRAPH_ENDPOINT,
      PREVIOUS_NEWS_QUERY,
      { cursor },
    );

    yield put(actionGetPreviousNewsSuccess(data));
  } catch (error) {
    yield put(actionGetPreviousNewsFailed());
  }
}

function* getRelatedNews(action) {
  try {
    const { tags } = action.payload;
    const variables = { tags };
    const response = yield call(
      request,
      process.env.REACT_APP_GRAPH_ENDPOINT,

      RELATED_QUERY,

      variables,
    );

    yield put(actionGetRelatedNewsSuccess(response));
  } catch (error) {
    yield put(actionGetRelatedNewsFailed());
  }
}

export default function* NEWSaga() {
  yield takeLeading(GET_ALL_NEWS, getAllNews);
  yield takeLeading(GET_LATEST_HIGHLIGHT_NEW, getLatestHighlightNew);
  yield takeLeading(GET_NEXT_NEWS, getNextNews);
  yield takeLeading(GET_PREVIOUS_NEWS, getPreviousNews);
  yield takeLeading(GET_RELATED_NEWS, getRelatedNews);
  yield takeLeading(GET_ALL_NEWS_IN_MONTH, getAllNewsInMonth);
}
