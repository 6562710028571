export const GET_ALL_NEWS = 'GET_ALL_NEWS';
export const GET_ALL_NEWS_SUCCESS = 'GET_ALL_NEWS_SUCCESS';
export const GET_ALL_NEWS_FAILED = 'GET_ALL_NEWS_FAILED';

export const GET_LATEST_HIGHLIGHT_NEW = 'GET_LATEST_HIGHLIGHT_NEW';
export const GET_LATEST_HIGHLIGHT_NEW_SUCCESS = 'GET_LATEST_HIGHLIGHT_NEW_SUCCESS';
export const GET_LATEST_HIGHLIGHT_NEW_FAILED = 'GET_LATEST_HIGHLIGHT_NEW_FAILED';

export const GET_PREVIOUS_NEWS = 'GET_PREVIOUS_NEWS';
export const GET_PREVIOUS_NEWS_SUCCESS = 'GET_PREVIOUS_NEWS_SUCCESS';
export const GET_PREVIOUS_NEWS_FAILED = 'GET_PREVIOUS_NEWS_FAILED';

export const GET_NEXT_NEWS = 'GET_NEXT_NEWS';
export const GET_NEXT_NEWS_SUCCESS = 'GET_NEXT_NEWS_SUCCESS';
export const GET_NEXT_NEWS_FAILED = 'GET_NEXT_NEWS_FAILED';

export const GET_RELATED_NEWS = 'GET_RELATED_NEWS';
export const GET_RELATED_NEWS_SUCCESS = 'GET_RELATED_NEWS_SUCCESS';
export const GET_RELATED_NEWS_FAILED = 'GET_RELATED_NEWS_FAILED';

export const GET_ALL_NEWS_IN_MONTH = 'GET_ALL_NEWS_IN_MONTH';
export const GET_ALL_NEWS_IN_MONTH_SUCCESS = 'GET_ALL_NEWS_IN_MONTH_SUCCESS';
export const GET_ALL_NEWS_IN_MONTH_FAILED = 'GET_ALL_NEWS_IN_MONTH_FAILED';
