import {
  ADD_FEED_BACK,
  ADD_FEED_BACK_SUCCESS,
  ADD_FEED_BACK_FAILED,
} from './actionTypes';

const initialState = {
  loading: false,
  feedback: null,
};

const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FEED_BACK:
      return {
        ...state,
        loading: true,
      };

    case ADD_FEED_BACK_SUCCESS:
      return {
        ...state,
        loading: false,
        feedback: action.payload,
      };

    case ADD_FEED_BACK_FAILED:
      return {
        ...state,
        loading: false,
        feedback: null,
      };

    default:
      return state;
  }
};

export default feedbackReducer;
