import React from 'react';

import { ReactComponent as LogoWithName } from 'assets/icons/LogoWithName.svg';
import { ReactComponent as FooterBackground } from 'assets/images/FooterBackground.svg';
import { ReactComponent as Phone } from 'assets/icons/Phone.svg';
import { ReactComponent as Global } from 'assets/icons/Global.svg';
import { ReactComponent as Mail } from 'assets/icons/Mail.svg';
import { ReactComponent as Location } from 'assets/icons/Location.svg';
import { ReactComponent as Fb } from 'assets/icons/Fb.svg';
import { ReactComponent as Twitter } from 'assets/icons/Twitter.svg';
import { ReactComponent as Youtube } from 'assets/icons/Youtube.svg';
import { ReactComponent as Instagram } from 'assets/icons/Instagram.svg';

import styles from './index.module.scss';

function Footers() {
  return (
    <footer>
      <LogoWithName className={styles.LogoWithName} />

      <div className={styles.InfoContainer}>
        <div className={styles.InfoTitle}>CONTACT US:</div>

        <div className={styles.InfoInnerContainer}>
          <Phone />

          <div>
            +84 28 386 81188
          </div>
        </div>

        <div className={styles.InfoInnerContainer}>
          <Global />

          <div>
            legal3.tech
          </div>
        </div>

        <div className={styles.InfoInnerContainer}>
          <Mail />

          <div>
            hey@legal3.tech
          </div>
        </div>

        <div className={styles.InfoInnerContainer}>
          <Location />

          <div>
            33 Tran Quy Khoach, D1, HCMC
          </div>
        </div>

        <span className={styles.Divider} />
      </div>

      <div className={styles.IconContainer}>
        <Fb />
        <Twitter />
        <Youtube />
        <Instagram />
      </div>

      <div className={styles.BottomFooter}>
        @ 2023
        {' '}
        <span>Legal3</span>
        . All Right Reserved |
        {' '}
        <span>Privacy Policy</span>
        {' '}
        |
        {' '}
        <span>Terms of Use</span>
      </div>

      <FooterBackground className={styles.FooterBackground} />
    </footer>
  );
}

export default Footers;
