import React from 'react';

import TeamMember from 'components/TeamMember/TeamMember';
import Partners from 'components/Partners/Partners';
import AboutUs from 'components/AboutUs/AboutUs';

function AboutPage() {
  return (
    <div>
      <AboutUs />

      <TeamMember />

      <Partners />
    </div>
  );
}

export default AboutPage;
