import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowToRight } from 'assets/icons/ArrowToRight.svg';

import SeaFood from 'assets/images/SeaFood.png';
import Farm from 'assets/images/Farm.png';
import Audience from 'assets/images/Audience.png';

import CaseCard from 'components/CaseCard/CaseCard';
import CaseCard1 from 'components/CaseCard1/CaseCard1';

import styles from './index.module.scss';

function Resources() {
  const navigate = useNavigate();

  const handleToCases = () => {
    navigate('/casestudy');
  };

  return (
    <div className={styles.Container}>
      <div className={styles.TitleContainer}>
        <div className={styles.Subtitle}>Resources</div>

        <div className={styles.Title}>CASE STUDY</div>

        <div className={styles.Description}>
          We are a highly flexible team that strives
          to meld our best practices with existing
          structures and processes of our clients.
        </div>
      </div>

      <div className={styles.CardContainer}>
        <Link to="/casestudy/1">
          <CaseCard
            imgUrl={SeaFood}
            description="Vietnamese seafood exporters were cheated by Echopack"
          />
        </Link>

        <Link to="/casestudy/2">
          <CaseCard1
            imgUrl={Farm}
            description="Comprehensive inspection of Hà Trung Agricultural and Industrial Co. Ltd."
          />
        </Link>

        <Link to="/casestudy/3">
          <CaseCard1
            imgUrl={Audience}
            description="Around 1,000 workers at Onewoo Garment Company stopped working together"
          />
        </Link>
      </div>

      <button type="button" className={styles.Button} onClick={handleToCases}>
        VIEW ALL CASES

        <ArrowToRight />
      </button>
    </div>
  );
}

export default Resources;
