import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { ReactComponent as Ellipse } from 'assets/icons/Ellipse.svg';
import { ReactComponent as ServicesDeco } from 'assets/images/ServicesDeco.svg';

import styles from './index.module.scss';

function OtherServies() {
  const { id } = useParams();

  return (
    <div className={styles.Container}>
      <div>
        <div className={styles.Subtitle}>Other Services</div>

        <div className={styles.List}>
          {id !== '1' && (
          <div className={styles.Item}>
            <Link to="/services/1">
              <span>01</span>
              <div>Technical & Blockchain & Crypto Legal Consultation</div>
            </Link>

            <div>
              <Ellipse />
            </div>

            <div className={styles.Divider} />
          </div>
          )}

          {id !== '2' && (
          <div className={styles.Item}>
            <Link to="/services/2">
              <span>02</span>
              <div>Finance Legal Consultation</div>
            </Link>

            <div>
              <Ellipse />
            </div>

            <div className={styles.Divider} />
          </div>
          )}

          {id !== '3' && (
          <div className={styles.Item}>
            <Link to="/services/3">
              <span>03</span>
              <div>Domestic & Foreign Investment Consultation</div>
            </Link>

            <div>
              <Ellipse />
            </div>

            <div className={styles.Divider} />
          </div>
          )}

          {id !== '4' && (
          <div className={styles.Item}>
            <Link to="/services/4">
              <span>04</span>
              <div>Merger & Acquisition Consultation</div>
            </Link>

            <div>
              <Ellipse />
            </div>

            <div className={styles.Divider} />
          </div>
          )}
        </div>
      </div>

      <ServicesDeco className={styles.ServicesDeco} />
    </div>
  );
}

export default OtherServies;
