export const GET_ALL_BLOGS = 'GET_ALL_BLOGS';
export const GET_ALL_BLOGS_SUCCESS = 'GET_ALL_BLOGS_SUCCESS';
export const GET_ALL_BLOGS_FAILED = 'GET_ALL_BLOGS_FAILED';

export const GET_LATEST_HIGHLIGHT_BLOG = 'GET_LATEST_HIGHLIGHT_BLOG';
export const GET_LATEST_HIGHLIGHT_BLOG_SUCCESS = 'GET_LATEST_HIGHLIGHT_BLOG_SUCCESS';
export const GET_LATEST_HIGHLIGHT_BLOG_FAILED = 'GET_LATEST_HIGHLIGHT_BLOG_FAILED';

export const GET_PREVIOUS_BLOGS = 'GET_PREVIOUS_BLOGS';
export const GET_PREVIOUS_BLOGS_SUCCESS = 'GET_PREVIOUS_BLOGS_SUCCESS';
export const GET_PREVIOUS_BLOGS_FAILED = 'GET_PREVIOUS_BLOGS_FAILED';

export const GET_NEXT_BLOGS = 'GET_NEXT_BLOGS';
export const GET_NEXT_BLOGS_SUCCESS = 'GET_NEXT_BLOGS_SUCCESS';
export const GET_NEXT_BLOGS_FAILED = 'GET_NEXT_BLOGS_FAILED';

export const GET_RELATED_BLOGS = 'GET_RELATED_BLOGS';
export const GET_RELATED_BLOGS_SUCCESS = 'GET_RELATED_BLOGS_SUCCESS';
export const GET_RELATED_BLOGS_FAILED = 'GET_RELATED_BLOGS_FAILED';

export const GET_ALL_BLOGS_IN_MONTH = 'GET_ALL_BLOGS_IN_MONTH';
export const GET_ALL_BLOGS_IN_MONTH_SUCCESS = 'GET_ALL_BLOGS_IN_MONTH_SUCCESS';
export const GET_ALL_BLOGS_IN_MONTH_FAILED = 'GET_ALL_BLOGS_IN_MONTH_FAILED';
