import { put, call, takeLeading } from 'redux-saga/effects';
import { request } from 'graphql-request';

import { toast } from 'react-toastify';

import {
  ADD_FEED_BACK,
} from './actionTypes';

import {
  actionAddFeedbackSuccess,
  actionAddFeedbackFailed,
} from './actions';

const ADD_FEED_BACK_QUERY = `
  mutation AddFeedback($data: FeedbackCreateInput!) {
    createFeedback(data: $data) {
      id
      username
      email
      phoneNumber
      subject
      message
    }
  }
`;

function* addFeedback(action) {
  try {
    const {
      name, email, phoneNumber, subject, message,
    } = action.payload;

    const feedbackData = {
      username: name,
      email,
      phoneNumber,
      subject,
      message,
    };

    const response = yield call(
      request,
      process.env.REACT_APP_GRAPH_ENDPOINT,
      ADD_FEED_BACK_QUERY,
      {
        data: feedbackData,
      },
    );

    toast.success('Success');

    yield put(actionAddFeedbackSuccess(response));
  } catch (error) {
    yield put(actionAddFeedbackFailed());
  }
}

export default function* FeedbackSaga() {
  yield takeLeading(ADD_FEED_BACK, addFeedback);
}
