import {
  GET_NEW_BY_SLUG,
  GET_NEW_BY_SLUG_SUCCESS,
  GET_NEW_BY_SLUG_FAILED,
} from './actionTypes';

const initialState = {
  loading: false,
  new: null,
};

const newReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_NEW_BY_SLUG:
      return {
        ...state,
        loading: true,
      };

    case GET_NEW_BY_SLUG_SUCCESS:
    {
      const n = action.payload;
      if (n) {
        return {
          ...state,
          new: n,
        };
      }
      return state;
    }

    case GET_NEW_BY_SLUG_FAILED:
      return {
        ...state,
        loading: false,
        new: initialState.new,
      };

    default:
      return state;
  }
};

export default newReducers;
