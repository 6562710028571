import { put, call, takeLeading } from 'redux-saga/effects';
import { request } from 'graphql-request';

import {
  GET_BLOG_BY_SLUG,
} from './actionTypes';

import {
  actionGetBlogBySlugSuccess,
  actionGetBlogBySlugFailed,
} from './actions';

const GET_BLOG_BY_SLUG_QUERY = `
  query MyQuery($slug: String!) {
    blog(where: { slug: $slug }) {
      coverImage {
        url
      }
      date
      excerpt
      slug
      title
      content {
        html
      }
      author {
        name
      }
      isHighlight
    }
  }
`;

function* getBlogBySlug(action) {
  try {
    const { slug } = action.payload;
    const response = yield call(
      request,
      process.env.REACT_APP_GRAPH_ENDPOINT,

      GET_BLOG_BY_SLUG_QUERY,

      { slug },
    );

    yield put(actionGetBlogBySlugSuccess(response));
  } catch (error) {
    yield put(actionGetBlogBySlugFailed());
  }
}

export default function* BlogSaga() {
  yield takeLeading(GET_BLOG_BY_SLUG, getBlogBySlug);
}
