import React from 'react';
import { useNavigate } from 'react-router-dom';

import { motion } from 'framer-motion';

import { ReactComponent as AboutDeco } from 'assets/images/AboutDeco.svg';
import { ReactComponent as ArrowToRight } from 'assets/icons/ArrowToRight.svg';

import styles from './index.module.scss';

const contentFadeMotion = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

function AboutTop() {
  const navigate = useNavigate();

  const handleToContact = () => {
    navigate('/contact');
  };

  return (
    <motion.div
      className={styles.AboutContainer}
      key="aboutcontainer"
      initial="initial"
      animate="animate"
      exit="exit"
      variants={contentFadeMotion}
      transition={{ duration: 0.5 }}
    >
      <div className={styles.InnerContainer}>
        <div>
          <div className={styles.TitleContainer}>
            <div className={styles.Title}>Legal3</div>

            <div className={styles.Subtitle1}>Since 2021</div>

            <div className={styles.Subtitle2}>
              PROTECT INVESTORS AND PROVIDE STARTUPS WITH
              <br />
              THE TOOLS TO PROPEL TO NEW HEIGHTS
            </div>
          </div>

          <div className={styles.InfoContainer}>
            <div className={styles.InfoLeftContainer}>
              <div className={styles.InfoItem}>
                <div className={styles.InfoNum}>25+</div>

                <div className={styles.InfoText}>
                  Cases of Technical & Blockchain & Crypto Legal Consultation
                </div>
              </div>

              <div className={styles.InfoItem}>
                <div className={styles.InfoNum}>20+</div>

                <div className={styles.InfoText}>
                  Cases of Foreign Investment License Applying
                </div>
              </div>

              <div className={styles.InfoItem}>
                <div className={styles.InfoNum}>15+</div>

                <div className={styles.InfoText}>
                  Cases of Blockchain & Crypto Copyright Registration
                </div>
              </div>
            </div>

            <button type="button" className={styles.ToContactButton} onClick={handleToContact}>
              Contact Us

              <ArrowToRight />
            </button>
          </div>
        </div>

        <AboutDeco className={styles.AboutDeco} />
      </div>
    </motion.div>
  );
}

export default AboutTop;
