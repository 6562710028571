import React from 'react';
import { useDispatch } from 'react-redux';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { actionAddFeedback } from 'store/feedback/actions';

import { ReactComponent as ArrowToRight } from 'assets/icons/ArrowToRight1.svg';

import { EMAIL_REGEX, TEXT_LENGTH_LIMIT, PHONE_REGEX } from 'constants';

import styles from './index.module.scss';

function ContactPage() {
  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      subject: '',
      message: '',
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name is required.')
        .max(TEXT_LENGTH_LIMIT.VERY_SHORT, `Name cannot exceed ${TEXT_LENGTH_LIMIT.VERY_SHORT} characters.`),
      email: Yup.string()
        .trim()
        .required('Email is required.')
        .matches(EMAIL_REGEX, 'Invalid email format.'),
      phoneNumber: Yup.string()
        .trim()
        .required('Phone number is required.')
        .matches(PHONE_REGEX, 'Invalid phone number.'),
      subject: Yup.string()
        .required('Subject is required.')
        .max(TEXT_LENGTH_LIMIT.SHORT, `Subject cannot exceed ${TEXT_LENGTH_LIMIT.SHORT} characters.`),
      message: Yup.string()
        .required('Your Message is required.')
        .max(TEXT_LENGTH_LIMIT.MEDIUM, `Your Message cannot exceed ${TEXT_LENGTH_LIMIT.MEDIUM} characters.`),
    }),

    onSubmit: (values) => {
      dispatch(actionAddFeedback(values));
    },
  });

  return (
    <div>
      <div className={styles.Container}>
        <div className={styles.InnerContainer}>
          <div className={styles.Subtitle}>Drop us a line</div>

          <div className={styles.Title}>IF YOU HAVE MORE QUESTIONS</div>

          <form onSubmit={validation.handleSubmit}>
            <div className={styles.InputContainer}>
              <input
                type="name"
                placeholder="Enter Your name"
                name="name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name || ''}
                className={styles.Input}
              />

              {validation.touched.name && validation.errors.name
                ? <div className={styles.Errors}>{validation.errors.name}</div>
                : null}
            </div>

            <div className={styles.TwoInputContainer}>
              <div className={styles.InputContainer}>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ''}
                  className={styles.Input}
                />

                {validation.touched.email && validation.errors.email
                  ? <div className={styles.Errors}>{validation.errors.email}</div>
                  : null}
              </div>

              <div className={styles.InputContainer}>
                <input
                  type="phoneNumber"
                  placeholder="Phone number"
                  name="phoneNumber"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phoneNumber || ''}
                  className={styles.Input}
                />

                {validation.touched.phoneNumber && validation.errors.phoneNumber
                  ? <div className={styles.Errors}>{validation.errors.phoneNumber}</div>
                  : null}
              </div>
            </div>

            <div className={styles.InputContainer}>
              <input
                type="subject"
                placeholder="Subject"
                name="subject"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.subject || ''}
                className={styles.Input}
              />

              {validation.touched.subject && validation.errors.subject
                ? <div className={styles.Errors}>{validation.errors.subject}</div>
                : null}
            </div>

            <div className={styles.MessageContainer}>
              <textarea
                type="message"
                placeholder="Your message"
                name="message"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.message || ''}
                className={styles.Input}
              />

              {validation.touched.message && validation.errors.message
                ? <div className={styles.Errors}>{validation.errors.message}</div>
                : null}
            </div>

            <button type="submit" className={styles.SubmitButton}>
              SEND IT OUT

              <ArrowToRight />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
