import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';

import {
  actionGetAllNews,
  actionGetAllNewsInMonth,
  actionGetLatestHighlightNew,
  actionGetNextNews,
  actionGetPreviousNews,
} from 'store/actions';

import ScheduleContact from 'components/ScheduleContact/ScheduleContact';

import { ReactComponent as ArrowToRight } from 'assets/icons/ArrowToRight1.svg';
import { ReactComponent as ToLink } from 'assets/icons/ToLink.svg';

import SmallCard from 'components/SmallCard/SmallCard';
import Card from './Card/Card';

import styles from './index.module.scss';

const generateMonthItems = (handleDateChange) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  return Array.from({ length: 12 }).map((_, index) => {
    const monthIndex = (currentMonth - index + 12) % 12;
    const year = currentYear + Math.floor((currentMonth - index) / 12);
    const date = new Date(year, monthIndex);
    const formattedMonth = `${date.toLocaleString('en-US', { month: 'long' })} ${year}`;
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

    return (
      <button
        type="button"
        key={uuidv4()}
        className={styles.ArchiveItem}
        onClick={() => handleDateChange(formattedDate)}
      >
        {formattedMonth}
      </button>
    );
  });
};

function NewPage() {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.News);

  const handleDateChange = (selectedDate) => {
    dispatch(actionGetAllNewsInMonth({ selectedDate }));
  };

  const months = generateMonthItems(handleDateChange);

  const calculateTimeToRead = (htmlContent) => {
    const wordsPerMinute = 150;

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const text = doc.documentElement.textContent || '';

    const words = text.trim().split(/\s+/);
    const wordCount = words.length;

    const readingTime = Math.ceil(wordCount / wordsPerMinute);

    return readingTime;
  };

  const formatDate = (dateString) => {
    const options = { month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const fetchOlderPosts = async () => {
    dispatch(actionGetPreviousNews({ cursor: data.news[data.news.length - 1].date }));
  };

  const fetchNewerPosts = async () => {
    dispatch(actionGetNextNews({ cursor: data.news[0].date }));
  };

  useEffect(() => {
    dispatch(actionGetAllNews());
    dispatch(actionGetLatestHighlightNew());
  }, []);

  return (
    <div>
      <div className={styles.Container}>
        <div className={styles.InnerContainer}>
          <div className={styles.LeftContainer}>
            {data.highlightedNew && (

            <div className={styles.HighlightContainer}>
              <div className={styles.Highlight}>HIGHLIGHT</div>

              <Link to={data.highlightedNew.slug}>
                <div className={styles.Title}>{data.highlightedNew.title}</div>
              </Link>

              <div className={styles.Subtitle}>
                {formatDate(data.highlightedNew.date)}
                , by
                {' '}
                {data.highlightedNew.author.name}
              </div>

              <img
                src={data.highlightedNew.coverImage.url}
                className={styles.Image}
                alt={data.highlightedNew.slug}
              />

              <div className={styles.Description}>{data.highlightedNew.excerpt}</div>

              <Link to={data.highlightedNew.slug}>
                <div className={styles.TimeToRead}>
                  {calculateTimeToRead(data.highlightedNew.content.html)}
                  {' '}
                  min read
                  <ArrowToRight />
                </div>
              </Link>
            </div>
            )}

            <div className={styles.CardContainer}>
              <div className={styles.Divider1} />

              {data.loading ? (
                <div className={styles.Loading}>
                  <div className={styles.LoadingLine} />
                </div>
              ) : (
                data.news.map((post) => (
                  <Link to={post.slug} key={post.slug}>
                    <Card
                      image={post.coverImage.url}
                      title={post.title}
                      description={post.excerpt}
                      timeToRead={calculateTimeToRead(post.content.html)}
                    />
                  </Link>
                ))
              )}

              <div className={styles.Divider} />
            </div>

            <div className={styles.SelectContainer}>
              <button type="button" className={styles.SelectItem} onClick={fetchOlderPosts}>
                <ToLink />

                Older
              </button>

              <button type="button" className={styles.SelectItem1} onClick={fetchNewerPosts}>
                Newest

                <ToLink />
              </button>
            </div>
          </div>

          <div className={styles.RightContainer}>
            <div className={styles.TopCardContainer}>
              {data.randomNews.length
                ? data.randomNews.map((post) => (
                  <Link to={post.slug} key={post.slug} className={styles.ToBlog}>
                    <SmallCard
                      image={post.coverImage.url}
                      title={post.title}
                    />
                  </Link>
                )) : <div className={styles.Card}>Card</div>}
            </div>

            <div className={styles.ArchiveContainer}>
              <div className={styles.Archive}>ARCHIVE</div>

              <div>
                {months}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ScheduleContact />
    </div>
  );
}

export default NewPage;
