import React from 'react';

import PropTypes from 'prop-types';

import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';

import styles from './index.module.scss';

function Layout({ children }) {
  return (
    <div className={styles.Container}>
      <Header />

      <main>
        {children}
      </main>

      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
