import React from 'react';

import PropTypes from 'prop-types';

import { TextReduceHelper } from 'utils';

import styles from './index.module.scss';

function SmallCard({
  image, title,
}) {
  return (
    <div className={styles.CardContainer}>
      <img src={image} alt={title} />

      <div className={styles.InfoCardContainer}>
        <div className={styles.Title}>{TextReduceHelper({ text: title, maxLength: 75 })}</div>
      </div>
    </div>
  );
}

SmallCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SmallCard;
