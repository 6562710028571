import {
  GET_ALL_BLOGS,
  GET_ALL_BLOGS_SUCCESS,
  GET_ALL_BLOGS_FAILED,

  GET_LATEST_HIGHLIGHT_BLOG,
  GET_LATEST_HIGHLIGHT_BLOG_SUCCESS,
  GET_LATEST_HIGHLIGHT_BLOG_FAILED,

  GET_PREVIOUS_BLOGS,
  GET_PREVIOUS_BLOGS_SUCCESS,
  GET_PREVIOUS_BLOGS_FAILED,

  GET_NEXT_BLOGS,
  GET_NEXT_BLOGS_SUCCESS,
  GET_NEXT_BLOGS_FAILED,

  GET_RELATED_BLOGS,
  GET_RELATED_BLOGS_SUCCESS,
  GET_RELATED_BLOGS_FAILED,

  GET_ALL_BLOGS_IN_MONTH,
  GET_ALL_BLOGS_IN_MONTH_SUCCESS,
  GET_ALL_BLOGS_IN_MONTH_FAILED,
} from './actionTypes';

const initialState = {
  loading: false,
  highlightedBlog: null,
  randomBlogs: [],
  blogs: [],
  relatedBlogs: [],
};

const blogsReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BLOGS:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_BLOGS_SUCCESS:
    {
      const { blogsConnection } = action.payload;
      if (blogsConnection && blogsConnection.edges && blogsConnection.edges.length > 0) {
        const { edges } = blogsConnection;
        return {
          ...state,
          blogs: edges.slice(0, 5).map((edge) => edge.node),
          randomBlogs: edges.slice(-2).map((edge) => edge.node),
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
      };
    }

    case GET_ALL_BLOGS_FAILED:
      return {
        ...state,
        loading: false,
        blogs: initialState.blogs,
      };

    case GET_LATEST_HIGHLIGHT_BLOG:
      return {
        ...state,
        loading: true,
      };

    case GET_LATEST_HIGHLIGHT_BLOG_SUCCESS:
    {
      const { blogsConnection } = action.payload;
      if (blogsConnection && blogsConnection.edges && blogsConnection.edges.length > 0) {
        const { edges } = blogsConnection;
        return {
          ...state,
          highlightedBlog: edges[0].node,
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
      };
    }

    case GET_LATEST_HIGHLIGHT_BLOG_FAILED:
      return {
        ...state,
        loading: false,
        highlightedBlog: initialState.highlightedBlog,
      };

    case GET_NEXT_BLOGS:
      return {
        ...state,
        loading: true,
      };

    case GET_NEXT_BLOGS_SUCCESS:
    {
      const { blogsConnection } = action.payload;
      if (blogsConnection && blogsConnection.edges && blogsConnection.edges.length > 0) {
        const { edges } = blogsConnection;
        return {
          ...state,
          blogs: edges.map((edge) => edge.node),
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
      };
    }

    case GET_NEXT_BLOGS_FAILED:
      return {
        ...state,
        loading: false,
        blogs: initialState.blogs,
      };

    case GET_PREVIOUS_BLOGS:
      return {
        ...state,
        loading: true,
      };

    case GET_PREVIOUS_BLOGS_SUCCESS:
    {
      const { blogsConnection } = action.payload;
      if (blogsConnection && blogsConnection.edges && blogsConnection.edges.length > 0) {
        const { edges } = blogsConnection;
        return {
          ...state,
          blogs: edges.map((edge) => edge.node),
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
      };
    }

    case GET_PREVIOUS_BLOGS_FAILED:
      return {
        ...state,
        loading: false,
        blogs: initialState.blogs,
      };

    case GET_RELATED_BLOGS:
      return {
        ...state,
        loading: true,
      };

    case GET_RELATED_BLOGS_SUCCESS:
    {
      const { blogs } = action.payload;
      return {
        ...state,
        relatedBlogs: blogs,
        loading: false,
      };
    }

    case GET_RELATED_BLOGS_FAILED:
      return {
        ...state,
        loading: false,
        relatedBlogs: initialState.relatedBlogs,
      };

    case GET_ALL_BLOGS_IN_MONTH:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_BLOGS_IN_MONTH_SUCCESS:
    {
      const { blogsConnection } = action.payload;
      if (blogsConnection && blogsConnection.edges && blogsConnection.edges.length > 0) {
        const { edges } = blogsConnection;
        return {
          ...state,
          blogs: edges.slice(0, 5).map((edge) => edge.node),
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
      };
    }

    case GET_ALL_BLOGS_IN_MONTH_FAILED:
      return {
        ...state,
        loading: false,
        blogs: initialState.blogs,
      };

    default:
      return state;
  }
};

export default blogsReducers;
