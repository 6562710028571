import {
  GET_ALL_CASES,
  GET_ALL_CASES_SUCCESS,
  GET_ALL_CASES_FAILED,

  GET_RELATED_CASES,
  GET_RELATED_CASES_SUCCESS,
  GET_RELATED_CASES_FAILED,
} from './actionTypes';

export const actionGetAllCases = (payload) => ({
  type: GET_ALL_CASES,
  payload,
});

export const actionGetAllCasesSuccess = (payload) => ({
  type: GET_ALL_CASES_SUCCESS,
  payload,
});

export const actionGetAllCasesFailed = () => ({
  type: GET_ALL_CASES_FAILED,
});

export const actionGetRelatedCases = (payload) => ({
  type: GET_RELATED_CASES,
  payload,
});

export const actionGetRelatedCasesSuccess = (payload) => ({
  type: GET_RELATED_CASES_SUCCESS,
  payload,
});

export const actionGetRelatedCasesFailed = () => ({
  type: GET_RELATED_CASES_FAILED,
});
