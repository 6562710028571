import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ToLink } from 'assets/icons/ToLink.svg';

import LOCATIONS from 'constants';

import styles from './index.module.scss';

function BlogTop() {
  return (
    <div className={styles.ServicesTopContainer}>
      <div className={styles.InnerContainer}>
        <div className={styles.ServicesTop}>
          <div className={styles.Location}>
            <Link to={LOCATIONS.HOME}>HOME</Link>
            {' '}
            <span>
              <ToLink />
              {' '}
              Blog
            </span>
          </div>

          <div className={styles.Title}>BLOG</div>
        </div>
      </div>
    </div>
  );
}

export default BlogTop;
