import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';

import { actionGetCaseBySlug } from 'store/actions';

import { ReactComponent as Tick } from 'assets/icons/Tick.svg';
import { ReactComponent as CaseDetailShield } from 'assets/images/CaseDetailShield.svg';
import { ReactComponent as Shield1 } from 'assets/images/Shield1.svg';

import RelatedCases from 'components/RelatedCases/RelatedCases';

import styles from './index.module.scss';

function CaseDetailPage() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.Case);

  const extractParagraphs = (html, startTag, endTag) => {
    const parsedHTML = new DOMParser().parseFromString(html, 'text/html');
    const startNode = parsedHTML.querySelector(startTag);
    const endNode = parsedHTML.querySelector(endTag);

    let currentNode = startNode;
    const paragraphs = [];

    while (currentNode && currentNode !== endNode) {
      if (currentNode.tagName === 'P') {
        paragraphs.push(currentNode);
      }
      currentNode = currentNode.nextElementSibling;
    }

    return paragraphs;
  };

  const parsedHTML = new DOMParser().parseFromString(data.case?.content?.html, 'text/html');
  const firstParagraph = parsedHTML.querySelector('p');
  const issuesHeading = parsedHTML.querySelector('h1:first-of-type');
  const consequenceHeading = parsedHTML.querySelector('h1:last-of-type');

  const numContainers = extractParagraphs(data.case?.content?.html, 'h1:first-of-type', 'h1:last-of-type');
  const tickContainers = extractParagraphs(data.case?.content?.html, 'h1:last-of-type', 'body > *');

  useEffect(() => {
    dispatch(actionGetCaseBySlug({ slug: id }));
  }, []);

  return (
    <div className={styles.Container}>
      <div className={styles.ContentContainer}>
        <div className={styles.TextContainer}>
          <div className={styles.TopContainer}>
            <div className={styles.ItemContainer}>
              <div className={styles.Title}>
                {data.case?.title}
              </div>

              <div>
                {firstParagraph && firstParagraph.textContent}
              </div>

              <div className={styles.Item}>
                {issuesHeading && issuesHeading.textContent}
              </div>

              <div>
                {numContainers && Array.from(numContainers).map((container, index) => (
                  <div className={styles.NumContainer} key={uuidv4()}>
                    <div className={styles.NumHead}>{index + 1}</div>
                    {container.textContent}
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.ImageContainer}>
              <img src={data.case?.coverImage.url} alt={id} />
              <Shield1 className={styles.Shield1} />
            </div>
          </div>

          <div className={styles.BottomContainer}>
            <CaseDetailShield className={styles.CaseDetailShield} />

            <div className={styles.ItemContainer}>
              <div className={styles.Item}>
                {consequenceHeading && consequenceHeading.textContent}
              </div>

              <div>
                {tickContainers && Array.from(tickContainers).map((container) => (
                  <div className={styles.TickContainer} key={uuidv4()}>
                    <div>
                      <Tick />
                    </div>
                    {container.textContent}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <RelatedCases tags={data.case?.tags} />
    </div>
  );
}

export default CaseDetailPage;
