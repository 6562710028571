import React from 'react';

import TeamMember from 'components/TeamMember/TeamMember';
import ScheduleContact from 'components/ScheduleContact/ScheduleContact';
import AboutUs from 'components/AboutUs/AboutUs';
import OurServices from './OurServices/OurServices';
import Resources from './Resources/Resources';

function HomePage() {
  return (
    <div>
      <OurServices />

      <AboutUs />

      <Resources />

      <TeamMember />

      <ScheduleContact />
    </div>
  );
}

export default HomePage;
