import React from 'react';

import { ReactComponent as JDIOne } from 'assets/icons/JDIOne.svg';
import { ReactComponent as Kardiachain } from 'assets/icons/Kardiachain.svg';
import { ReactComponent as Dragon } from 'assets/icons/dragon.svg';
import { ReactComponent as Logo10X } from 'assets/icons/10X.svg';
import { ReactComponent as DEHR } from 'assets/icons/DEHR.svg';

import styles from './index.module.scss';

function Partners() {
  return (
    <div className={styles.Container}>
      <div className={styles.Subtitle}>
        Our
      </div>

      <div className={styles.Title}>
        PARTNERS
      </div>

      <div className={styles.PartnersContainer}>
        <div className={styles.Partner}>
          <JDIOne />
          <span className={styles.TopLeft} />
          <span className={styles.TopRight} />
          <span className={styles.BottomLeft} />
          <span className={styles.BottomRight} />
        </div>

        <div className={styles.Partner}>
          <Kardiachain />
          <span className={styles.TopLeft} />
          <span className={styles.TopRight} />
          <span className={styles.BottomLeft} />
          <span className={styles.BottomRight} />
        </div>

        <div className={styles.Partner}>
          <Dragon />
          <span className={styles.TopLeft} />
          <span className={styles.TopRight} />
          <span className={styles.BottomLeft} />
          <span className={styles.BottomRight} />
        </div>

        <div className={styles.Partner}>
          <Logo10X />
          <span className={styles.TopLeft} />
          <span className={styles.TopRight} />
          <span className={styles.BottomLeft} />
          <span className={styles.BottomRight} />
        </div>

        <div className={styles.Partner}>
          <DEHR />
          <span className={styles.TopLeft} />
          <span className={styles.TopRight} />
          <span className={styles.BottomLeft} />
          <span className={styles.BottomRight} />
        </div>
      </div>
    </div>
  );
}

export default Partners;
