import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';

import LOCATIONS from 'constants';

import Layout from 'Layout/Layout';
import HomePage from 'pages/HomePage/HomePage';
import ServicesPage from 'pages/ServicesPage/ServicesPage';
import ServiceDetailsPage from 'pages/ServiceDetailsPage/ServiceDetailsPage';
import CaseStudyPage from 'pages/CaseStudyPage/CaseStudyPage';
import CaseDetailPage from 'pages/CaseDetailPage/CaseDetailPage';
import BlogPage from 'pages/BlogPage/BlogPage';
import BlogDetailsPage from 'pages/BlogDetailsPage/BlogDetailsPage';
import AboutPage from 'pages/AboutPage/AboutPage';
import ContactPage from 'pages/ContactPage/ContactPage';
import NewPage from 'pages/NewPage/NewPage';
import NewDetailsPage from 'pages/NewDetailsPage/NewDetailsPage';

const withAnimation = (Component) => (
  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
    <Component />
  </motion.div>
);

function App() {
  const location = useLocation();

  useEffect(() => {
    const routeTitles = {
      '/': 'Home',
      '/about': 'About',
      '/services': 'Services',
      '/contact': 'Contact',
      '/casestudy': 'Case Study',
      '/blog': 'Blog',
      '/new': 'New',
    };

    const currentPath = location.pathname;
    let title = 'Page';

    // Check if currentPath includes specific strings
    if (currentPath.includes('/services/')) {
      title = 'Services';
    } else if (currentPath.includes('/casestudy/')) {
      title = 'Case Study';
    } else if (currentPath.includes('/blog/')) {
      title = 'Blog';
    } else if (currentPath.includes('/new/')) {
      title = 'New';
    } else {
      title = routeTitles[currentPath] || title;
    }

    document.title = `${title} | Legal3`;
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Layout>
      <AnimatePresence mode="await">
        <Routes location={location} key={location.pathname}>
          <Route exact path={LOCATIONS.HOME} element={withAnimation(HomePage)} />

          <Route exact path={LOCATIONS.SERVICES} element={withAnimation(ServicesPage)} />

          <Route exact path={`${LOCATIONS.SERVICES}/:id`} element={withAnimation(ServiceDetailsPage)} />

          <Route exact path={LOCATIONS.CASESTUDY} element={withAnimation(CaseStudyPage)} />

          <Route exact path={`${LOCATIONS.CASESTUDY}/:id`} element={withAnimation(CaseDetailPage)} />

          <Route exact path={LOCATIONS.BLOG} element={withAnimation(BlogPage)} />

          <Route exact path={`${LOCATIONS.BLOG}/:id`} element={withAnimation(BlogDetailsPage)} />

          <Route exact path={LOCATIONS.NEW} element={withAnimation(NewPage)} />

          <Route exact path={`${LOCATIONS.NEW}/:id`} element={withAnimation(NewDetailsPage)} />

          <Route exact path={LOCATIONS.ABOUT} element={withAnimation(AboutPage)} />

          <Route exact path={LOCATIONS.CONTACT} element={withAnimation(ContactPage)} />
        </Routes>
      </AnimatePresence>
    </Layout>
  );
}

export default App;
