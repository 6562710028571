import {
  GET_BLOG_BY_SLUG,
  GET_BLOG_BY_SLUG_SUCCESS,
  GET_BLOG_BY_SLUG_FAILED,

} from './actionTypes';

export const actionGetBlogBySlug = (payload) => ({
  type: GET_BLOG_BY_SLUG,
  payload,
});

export const actionGetBlogBySlugSuccess = (payload) => ({
  type: GET_BLOG_BY_SLUG_SUCCESS,
  payload,
});

export const actionGetBlogBySlugFailed = () => ({
  type: GET_BLOG_BY_SLUG_FAILED,
});
