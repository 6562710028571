import React from 'react';
import { Link } from 'react-router-dom';

import { motion } from 'framer-motion';

import { ReactComponent as ToLink } from 'assets/icons/ToLink.svg';

import LOCATIONS from 'constants';

import styles from './index.module.scss';

const contentFadeMotion = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

function ServicesDetailTop() {
  return (
    <motion.div
      key="hometop"
      initial="initial"
      animate="animate"
      exit="exit"
      variants={contentFadeMotion}
      transition={{ duration: 0.5 }}
      className={styles.ServicesTopContainer}
    >
      <div className={styles.InnerContainer}>
        <div className={styles.ServicesTop}>
          <div className={styles.Location}>
            <Link to={LOCATIONS.HOME}>HOME</Link>
            {' '}
            <ToLink />

            <Link to="/services">SERVICES</Link>
            <span>
              <ToLink />
              {' '}
              Details
            </span>
          </div>

          <div className={styles.Title}>
            SERVICES DETAILS
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default ServicesDetailTop;
