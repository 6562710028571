import {
  ADD_FEED_BACK,
  ADD_FEED_BACK_SUCCESS,
  ADD_FEED_BACK_FAILED,

} from './actionTypes';

export const actionAddFeedback = (payload) => ({
  type: ADD_FEED_BACK,
  payload,
});

export const actionAddFeedbackSuccess = (payload) => ({
  type: ADD_FEED_BACK_SUCCESS,
  payload,
});

export const actionAddFeedbackFailed = () => ({
  type: ADD_FEED_BACK_FAILED,
});
