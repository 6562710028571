import { put, call, takeLeading } from 'redux-saga/effects';
import { request } from 'graphql-request';

import {
  GET_NEW_BY_SLUG,
} from './actionTypes';

import {
  actionGetNewBySlugSuccess,
  actionGetNewBySlugFailed,
} from './actions';

const GET_NEW_BY_SLUG_QUERY = `
  query MyQuery($slug: String!) {
    new(where: { slug: $slug }) {
      coverImage {
        url
      }
      date
      excerpt
      slug
      title
      content {
        html
      }
      author {
        name
      }
      isHighlight
    }
  }
`;

function* getNewBySlug(action) {
  try {
    const { slug } = action.payload;
    const response = yield call(
      request,
      process.env.REACT_APP_GRAPH_ENDPOINT,

      GET_NEW_BY_SLUG_QUERY,

      { slug },
    );

    yield put(actionGetNewBySlugSuccess(response.new));
  } catch (error) {
    yield put(actionGetNewBySlugFailed());
  }
}

export default function* newSaga() {
  yield takeLeading(GET_NEW_BY_SLUG, getNewBySlug);
}
