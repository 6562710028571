import {
  GET_ALL_CASES,
  GET_ALL_CASES_SUCCESS,
  GET_ALL_CASES_FAILED,

  GET_RELATED_CASES,
  GET_RELATED_CASES_SUCCESS,
  GET_RELATED_CASES_FAILED,
} from './actionTypes';

const initialState = {
  loading: false,
  cases: [],
  relatedCases: [],
};

const casesReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CASES:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_CASES_SUCCESS:
    {
      const { casesConnection } = action.payload;
      if (casesConnection && casesConnection.edges && casesConnection.edges.length > 0) {
        const { edges } = casesConnection;
        return {
          ...state,
          cases: edges.map((edge) => edge.node),
        };
      }
      return state;
    }

    case GET_ALL_CASES_FAILED:
      return {
        ...state,
        loading: false,
        cases: initialState.cases,
      };

    case GET_RELATED_CASES:
      return {
        ...state,
        loading: true,
      };

    case GET_RELATED_CASES_SUCCESS:
    {
      const { cases, fallbackItems } = action.payload;
      const relatedCases = cases.slice(0, 3);

      if (relatedCases.length < 3 && fallbackItems.length > 0) {
        relatedCases.push(fallbackItems[0]);
      }

      return {
        ...state,
        relatedCases,
      };
    }

    case GET_RELATED_CASES_FAILED:
      return {
        ...state,
        loading: false,
        relatedCases: initialState.relatedCases,
      };

    default:
      return state;
  }
};

export default casesReducers;
