import React from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as Service1 } from 'assets/icons/Service1.svg';
import { ReactComponent as Service2 } from 'assets/icons/Service2.svg';
import { ReactComponent as Service3 } from 'assets/icons/Service3.svg';
import { ReactComponent as Service4 } from 'assets/icons/Service4.svg';
import { ReactComponent as Tick } from 'assets/icons/Tick.svg';

import RelatedCases from 'components/RelatedCases/RelatedCases';
import OtherServies from './OtherServices/OtherServies';

import styles from './index.module.scss';

function ServiceDetailsPage() {
  const { id } = useParams();
  return (
    <div className={styles.Container}>
      {id === '1' && (
      <div className={styles.ContentContainer}>
        <div className={styles.ShieldContainer}>
          <Service1 />
        </div>

        <div className={styles.TextContainer}>
          <div className={styles.Title}>Technical & Blockchain & Crypto Legal Consultation</div>

          <div>
            Currently, blockchain technology is developing rapidly and
            is increasingly being applied in many fields, especially projects
            related to software development, crypto, NFT games, etc. To increase
            legitimacy and transparency and professionalism, especially the
            legality for Blockchain projects in the eyes of investors and
            customers, businesses that own Blockchain projects need to have
            a unit specializing in consulting, supporting, accompanying and
            providing Full legal consulting service, solving all legal issues for the project.
          </div>

          <div className={styles.Item}>
            The role of legal consultants in the Blockchain & Crypto field
          </div>

          <div>
            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              Legal advice is given by experts / lawyers who are knowledgeable
              about the law in general and Blockchain & Crypto in particular.
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              Blockchain projects that are consulted with experts will be able
              to do business legally, safely, knowingly and avoiding risks related
              to the operation process, operational structure, operational apparatus,
              and finance. and project personnel.
            </div>
          </div>

          <div className={styles.Item}>
            Legal3&apos;s services
          </div>

          <div>
            Legal3 is an expert in the field of legal advice on Blockchain & Crypto
            on a domestic and international scale, will help Enterprises in the following matters:
          </div>

          <div>
            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>1</div>

              <div>
                Currently, there is no legal framework for blockchain in Vietnam,
                so Legal3 will focus on supporting and analyzing for clients the following issues:
                <ul>
                  <li>
                    Know the categories of work that can and cannot be done in Vietnam.
                  </li>

                  <li>
                    In case it is not possible to do it in Vietnam, where can it be done?
                  </li>
                </ul>

                How is the process done and what documents and contracts are needed to perform.
              </div>
            </div>

            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>2</div>

              Consulting on issues related to exchange of goods in the blockchain field.
            </div>

            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>3</div>

              Consulting on issues related to exchange of goods in the blockchain field.
            </div>

            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>4</div>

              Legal3 supports the establishment of Blockchain and crypto companies in
              countries where it is allowed to operate such as Singapore, USA, BVI, etc.
            </div>

            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>5</div>

              Legal3 supports customers to apply for business licenses, work permits,
              office space leasing, receive correspondence, and other management and
              administration activities in Singapore, USA, BVI, etc.
            </div>

            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>6</div>

              <div>
                Issuing Legal Advice Letters related to Blockchain Projects such as:

                <ul>
                  <li>
                    Conduct analysis of all project documents, then evaluate the project from a
                    legal perspective to make appropriate adjustments.
                  </li>

                  <li>
                    Identify existing or hidden risks in the project to propose solutions and make a
                    comprehensive risk contingency plan for the project.
                  </li>

                  <li>
                    Consulting on all legal issues for the project / enterprise owning the project.
                  </li>

                  <li>
                    The legal advice is directly performed by experienced experts/lawyer.
                  </li>
                </ul>
              </div>
            </div>

            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>7</div>

              Drafting, building and registering copyright for blockchain project in Vietnam.
            </div>
          </div>
        </div>
      </div>
      )}

      {id === '2' && (
      <div className={styles.ContentContainer}>
        <div>
          <Service2 />
        </div>

        <div className={styles.TextContainer}>
          <div className={styles.Title}>Finance Legal Consultation</div>

          <div>
            Legal3 will support customers and perform the following services:
          </div>

          <div>
            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>1</div>

              Advise on the revenue accounting of the protocol so that
              it can be transparent and clear to investors.
            </div>

            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>2</div>

              Advice on how to move the cash flow of the business.
            </div>
          </div>
        </div>
      </div>
      )}

      {id === '3' && (
      <div className={styles.ContentContainer}>
        <div>
          <Service3 />
        </div>

        <div className={styles.TextContainer}>
          <div className={styles.Title}>Domestic & Foreign Investment Consultation</div>

          <div>
            Legal3 will support customers and perform the following services:
          </div>

          <div>
            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>1</div>

              Consulting and drafting all kinds of commercial, investment,
              cooperation contracts, etc.
            </div>

            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>2</div>

              Consulting on the investment of crypto investment funds from abroad to Vietnam.
            </div>

            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>3</div>

              <div>
                Carry out procedures for foreign investors to invest in Vietnam such as:

                <ul>
                  <li>
                    Carry out investment procedures, apply for an Investment
                    Registration Certificate
                    Apply for a Business Registration Certificate.
                  </li>

                  <li>
                    Apply for a Business Registration Certificate.
                  </li>

                  <li>
                    Consulting on bringing money from abroad to
                    invest in Vietnamese enterprises in accordance with the law.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}

      {id === '4' && (
      <div className={styles.ContentContainer}>
        <div>
          <Service4 />
        </div>

        <div className={styles.TextContainer}>
          <div className={styles.Title}>Merger & Acquisition Consultation</div>

          <div>
            Legal3&apos;s services are mainly for Fintech and SME businesses:
          </div>

          <div className={styles.Item}>
            The period before the merger, acquisition
          </div>

          <ul>
            <li>
              Support finding businesses that match the requirements of
              customers in case customers have not found the object.
            </li>

            <li>
              Advising on conditions, processes and general provisions
              of the law on business acquisition, merger, and division.
            </li>

            <li>
              Assess and analyze business entities in terms of legal,
              property, and financial (depending on the role of the
              customer as the buyer or seller to provide a suitable set of evaluation criteria).
            </li>

            <li>
              Prepare reports analyzing strengths and weaknesses
              based on legal records and analyzing target businesses.
            </li>

            <li>
              Provide professional legal opinions for the specific case
              of the client to agree on the merger and acquisition plan.
            </li>

            <li>
              Consulting on issues related to enterprise value,
              purchase price, selling price and taxes and fees of the state.
            </li>

            <li>
              Advising on other legal requirements of customers.
            </li>
          </ul>

          <div className={styles.Item}>
            Stage of carrying out procedures for mergers and acquisitions
          </div>

          <ul>
            <li>
              After reaching an agreement on the business merger and acquisition
              plan at phase 1, Legal3 will conduct the drafting of all documents
              and documents in accordance with regulations.
            </li>

            <li>
              Legal3 will represent customers to contact and directly work with
              departments and agencies to carry out merger and acquisition
              procedures until a new License is issued.
            </li>

            <li>
              Legal3 will represent customers to contact the tax administration
              agency of the business to make tax declaration and pay tax
              according to regulations (if any).
            </li>
          </ul>

          <div className={styles.Item}>
            The stage after carrying out the procedures for business mergers and acquisitions
          </div>

          <ul>
            <li>
              Legal3 will advise on solutions for new businesses
              to be bought, sold, merged, to operate stably and develop sustainably.
            </li>

            <li>
              Legal3 provides regular monthly consulting support
              for businesses (if the business needs to do it).
            </li>
          </ul>
        </div>
      </div>
      )}

      <RelatedCases />

      <OtherServies />
    </div>
  );
}

export default ServiceDetailsPage;
