import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Ellipse } from 'assets/icons/Ellipse.svg';
import { ReactComponent as ArrowToRight1 } from 'assets/icons/ArrowToRight1.svg';
import { ReactComponent as Shield } from 'assets/icons/Shield.svg';
import { ReactComponent as HomeConnect } from 'assets/images/HomeConnect.svg';

import styles from './index.module.scss';

function OurServices() {
  return (
    <div className={styles.Container}>
      <div>
        <div className={styles.Subtitle}>Our Services</div>

        <div className={styles.Title}>
          BESPOKE SOLUTIONS HELP
          <br />
          YOU MAKE AN IMPACT.
        </div>

        <div className={styles.List}>
          <div className={`${styles.Active} ${styles.Item}`}>
            <Link to="/services/1">
              <span>01</span>
              <div>Technical & Blockchain & Crypto Legal Consultation</div>
              <ArrowToRight1 className={styles.ArrowToRight} />
            </Link>

            <div>
              <Shield />
            </div>

            <div className={styles.Divider} />
          </div>

          <div className={styles.Item}>
            <Link to="/services/1">
              <span>02</span>
              <div>Finance Legal Consultation</div>
            </Link>

            <div>
              <Ellipse />
            </div>

            <div className={styles.Divider} />
          </div>

          <div className={styles.Item}>
            <Link to="/services/1">
              <span>03</span>
              <div>Domestic & Foreign Investment Consultation</div>
            </Link>

            <div>
              <Ellipse />
            </div>

            <div className={styles.Divider} />
          </div>

          <div className={styles.Item}>
            <Link to="/services/1">
              <span>04</span>
              <div>Merger & Acquisition Consultation</div>
            </Link>

            <div>
              <Ellipse />
            </div>

            <div className={styles.Divider} />
          </div>
        </div>
      </div>

      <HomeConnect className={styles.HomeConnect} />
    </div>
  );
}

export default OurServices;
