import React from 'react';

import GoergeNguyen from 'assets/images/GoergeNguyen.png';
import GiangDang from 'assets/images/GiangDang.png';
import ThangNguyen from 'assets/images/ThangNguyen.png';
import GiaoQuynh from 'assets/images/GiaoQuynh.png';

import { ReactComponent as MemberBackground } from 'assets/images/MemberBackground.svg';

import InfoMember from './InfoMember/InfoMember';

import styles from './index.module.scss';

function TeamMember() {
  return (
    <div className={styles.Container}>
      <div className={styles.SubTitle}>Core Team</div>

      <div className={styles.Title}>MEET OUR TEAM</div>

      <div className={styles.MemberContainer}>
        <InfoMember image={GoergeNguyen} name="GEORGE NGUYEN" title="Managing Partner" />
        <InfoMember image={GiaoQuynh} name="RUBY HUYNH" title="Legal Consultant" />
        <InfoMember image={GiangDang} name="GIANG DANG" title="Partner" />
        <InfoMember image={ThangNguyen} name="THANG NGUYEN" title="Project Coordinator" />
      </div>

      <MemberBackground className={styles.Background} />
    </div>
  );
}

export default TeamMember;
