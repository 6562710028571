import { all, fork } from 'redux-saga/effects';

import BlogsSaga from 'store/blogs/saga';
import BlogSaga from 'store/blog/saga';
import NewsSaga from 'store/news/saga';
import NewSaga from 'store/new/saga';
import CasesSaga from './cases/saga';
import CaseSaga from './case/saga';
import FeedbackSaga from './feedback/saga';

export default function* rootSaga() {
  yield all([
    fork(BlogsSaga),
    fork(BlogSaga),

    fork(NewsSaga),
    fork(NewSaga),

    fork(CasesSaga),
    fork(CaseSaga),

    fork(FeedbackSaga),
  ]);
}
