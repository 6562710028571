import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Ellipse } from 'assets/icons/Ellipse.svg';
import { ReactComponent as ArrowToRight1 } from 'assets/icons/ArrowToRight1.svg';
import { ReactComponent as Shield } from 'assets/icons/Shield.svg';
import { ReactComponent as ServicesDeco } from 'assets/images/ServicesDeco.svg';

import ScheduleContact from 'components/ScheduleContact/ScheduleContact';

import ServicesBanner from 'assets/images/ServicesBanner.png';

import styles from './index.module.scss';

function ServicesPage() {
  return (
    <div>
      <div className={styles.InfoContainer}>
        <div className={styles.TextContainer}>
          <div className={styles.Title}>
            The best and safest legal advice in Vietnam.
          </div>

          <div>
            Legal3 after many years of operation in the field of legal advice,
            legal services in general and legal advice in the field of Technical &
            Blockchain & Crypto has affirmed the prestige,
            trust and confidence of customers in and overseas about the services
            provided by Legal3.
            Legal3 with the advantage of having a team of lawyers,
            lawyers, consultants with extensive knowledge, experience,
            responsibility and professional ethics will ensure to provide
            domestic and foreign clients with consulting services.
            The best and safest legal advice in Vietnam.
            <br />
            <br />
            Legal3 has been supporting many successful
            partners and startups in Vietnam, implementing
            Technical & Blockchain & Crypto projects as well
            as carrying out legal procedures, solving difficulties
            and obstacles problems in the process of doing business
            in Vietnam and investing abroad.
            <br />
            <br />
            <div className={styles.BottomText}>
              If you are facing any problems in the business process,
              please contact us immediately for the best support.
            </div>
          </div>
        </div>

        <div className={styles.ImageContainer}>
          <img src={ServicesBanner} alt="Services" />
        </div>
      </div>

      <div className={styles.Container}>
        <div>
          <div className={styles.Subtitle}>All Services</div>

          <div className={styles.List}>
            <div className={`${styles.Active} ${styles.Item}`}>
              <Link to="/services/1">
                <span>01</span>
                <div>Technical & Blockchain & Crypto Legal Consultation</div>
                <ArrowToRight1 />
              </Link>

              <div>
                <Shield />
              </div>

              <div className={styles.Divider} />
            </div>

            <div className={styles.Item}>
              <Link to="/services/2">
                <span>02</span>
                <div>Finance Legal Consultation</div>
              </Link>

              <div>
                <Ellipse />
              </div>

              <div className={styles.Divider} />
            </div>

            <div className={styles.Item}>
              <Link to="/services/3">
                <span>03</span>
                <div>Domestic & Foreign Investment Consultation</div>
              </Link>

              <div>
                <Ellipse />
              </div>

              <div className={styles.Divider} />
            </div>

            <div className={styles.Item}>
              <Link to="/services/4">
                <span>04</span>
                <div>Merger & Acquisition Consultation</div>
              </Link>

              <div>
                <Ellipse />
              </div>

              <div className={styles.Divider} />
            </div>
          </div>
        </div>

        <ServicesDeco className={styles.ServicesDeco} />
      </div>

      <ScheduleContact />
    </div>
  );
}

export default ServicesPage;
