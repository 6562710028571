import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowToRight } from 'assets/icons/ArrowToRight.svg';
import { ReactComponent as ScheduleDeco } from 'assets/images/ScheduleDeco.svg';

import styles from './index.module.scss';

function ScheduleContact() {
  const navigate = useNavigate();

  const handleToContact = () => {
    navigate('/contact');
  };

  return (
    <div className={styles.Container}>
      <div className={styles.SubTitle}>
        Schedule a Consultation
      </div>

      <div className={styles.Title}>
        WE ARE HERE TO HELP YOU
      </div>

      <button type="button" className={styles.Button} onClick={handleToContact}>
        <span>
          <div>
            CONTACT US
          </div>

          <ArrowToRight />
        </span>
      </button>

      <ScheduleDeco className={styles.ScheduleDeco1} />
      <ScheduleDeco className={styles.ScheduleDeco2} />
    </div>
  );
}

export default ScheduleContact;
