import { put, call, takeLeading } from 'redux-saga/effects';
import { request } from 'graphql-request';

import {
  GET_CASE_BY_SLUG,
} from './actionTypes';

import {
  actionGetCaseBySlugSuccess,
  actionGetCaseBySlugFailed,
} from './actions';

const GET_CASE_BY_SLUG_QUERY = `
  query MyQuery($slug: String!) {
    case(where: { slug: $slug }) {
      coverImage {
        url
      }
      slug
      title
      tags
      content {
        html
      }
    }
  }
`;

function* getCaseBySlug(action) {
  try {
    const { slug } = action.payload;
    const response = yield call(
      request,
      process.env.REACT_APP_GRAPH_ENDPOINT,

      GET_CASE_BY_SLUG_QUERY,

      { slug },
    );

    yield put(actionGetCaseBySlugSuccess(response));
  } catch (error) {
    yield put(actionGetCaseBySlugFailed());
  }
}

export default function* CaseSaga() {
  yield takeLeading(GET_CASE_BY_SLUG, getCaseBySlug);
}
