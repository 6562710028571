import {
  GET_CASE_BY_SLUG,
  GET_CASE_BY_SLUG_SUCCESS,
  GET_CASE_BY_SLUG_FAILED,

} from './actionTypes';

export const actionGetCaseBySlug = (payload) => ({
  type: GET_CASE_BY_SLUG,
  payload,
});

export const actionGetCaseBySlugSuccess = (payload) => ({
  type: GET_CASE_BY_SLUG_SUCCESS,
  payload,
});

export const actionGetCaseBySlugFailed = () => ({
  type: GET_CASE_BY_SLUG_FAILED,
});
