import React from 'react';

import { motion } from 'framer-motion';

function WithAnimation(Component, key, variants) {
  return (
    <motion.div
      key={key}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={variants}
      transition={{ duration: 0.5 }}
    >
      <Component />
    </motion.div>
  );
}

export default WithAnimation;
