import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { actionGetNewBySlug, actionGetRelatedNews } from 'store/actions';

import { ReactComponent as ToLink } from 'assets/icons/ToLink.svg';
import { ReactComponent as Share } from 'assets/icons/Share.svg';
import { ReactComponent as Fb } from 'assets/icons/Fb.svg';
import { ReactComponent as Twitter } from 'assets/icons/Twitter.svg';

import SmallCard from 'components/SmallCard/SmallCard';

import styles from './index.module.scss';

function NewDetailsPage() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.New);
  const relatedNews = useSelector((state) => state.News.relatedNews);

  const [notificationVisible, setNotificationVisible] = useState(false);

  const showNotification = () => {
    setNotificationVisible(true);

    // Hide the notification after a delay (e.g., 2 seconds)
    setTimeout(() => {
      setNotificationVisible(false);
    }, 2000); // Change the delay as needed
  };

  const copyCurrentUrl = () => {
    const currentUrl = window.location.href;
    // Code to copy 'currentUrl' to clipboard
    const textarea = document.createElement('textarea');
    textarea.value = currentUrl;
    document.body.appendChild(textarea);

    // Select and copy the text
    textarea.select();
    document.execCommand('copy');

    // Remove the temporary textarea
    document.body.removeChild(textarea);
  };

  const createFacebookPost = () => {
    const currentUrl = encodeURI(window.location.href);
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`;

    window.open(facebookShareUrl, '_blank');
  };

  const createTwitterPost = () => {
    const currentUrl = encodeURI(window.location.href);
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${currentUrl}`;

    window.open(twitterShareUrl, '_blank');
  };

  const formatDate = (dateString) => {
    const options = { month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const parsedHTML = new DOMParser().parseFromString(data.new?.content?.html, 'text/html');

  useEffect(() => {
    dispatch(actionGetNewBySlug({ slug: id }));
    dispatch(actionGetRelatedNews({ tags: ['Scam', 'Fraud'] }));
  }, []);

  return (
    <div>
      <div className={styles.Container}>
        <div className={`${styles.InnerContainer} ${data.new?.isHighlight ? styles.Highlight : ''}`}>
          {data.new?.isHighlight
            ? (
              <img
                src={data.new.coverImage.url}
                alt={data.new.slug}
                className={styles.HighlightImage}
              />
            )
            : ''}

          <div className={styles.ShareWrapper}>
            <div className={styles.ShareContainer}>
              <Share onClick={() => { copyCurrentUrl(); showNotification(); }} />
              <Fb onClick={createFacebookPost} />
              <Twitter onClick={createTwitterPost} />

              <div className={`${styles.Notification} ${notificationVisible ? styles.Active : ''}`}>
                URL Copied!
              </div>
            </div>
          </div>

          {data.new && data.new.content ? (
            <div className={styles.BlogContainer}>
              <Link to="/new" className={styles.BackButton}>
                <ToLink />
                BACK
              </Link>

              <div className={styles.Title}>{data.new.title}</div>

              <div className={styles.Subtitle}>
                {formatDate(data.new.date)}
                , by
                {' '}
                {data.new.author?.name ? (
                  <span>{data.new.author.name}</span>
                ) : (
                  <span>Anonymous</span>
                )}
              </div>

              {data.new.isHighlight
                ? ''
                : <img src={data.new.coverImage.url} alt={data.new.slug} />}

              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: parsedHTML.documentElement.outerHTML }}
                className={styles.Content}
              />
            </div>
          ) : <div className={styles.EmptyBlog} />}

          <div className={styles.RightContainer}>
            <div className={styles.ReadMore}>
              READ MORE
            </div>

            <div className={styles.TopCardContainer}>
              {relatedNews.length
                ? relatedNews.map((post) => (
                  <Link to={`/new/${post.slug}`} key={post.slug} className={styles.ToBlog}>
                    <SmallCard
                      image={post.coverImage.url}
                      title={post.title}
                    />
                  </Link>
                )) : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewDetailsPage;
