import {
  GET_CASE_BY_SLUG,
  GET_CASE_BY_SLUG_SUCCESS,
  GET_CASE_BY_SLUG_FAILED,
} from './actionTypes';

const initialState = {
  loading: false,
  case: null,
};

const caseReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_CASE_BY_SLUG:
      return {
        ...state,
        loading: true,
      };

    case GET_CASE_BY_SLUG_SUCCESS:
    {
      const response = action.payload;
      if (response.case) {
        return {
          ...state,
          case: response.case,
        };
      }
      return state;
    }

    case GET_CASE_BY_SLUG_FAILED:
      return {
        ...state,
        loading: false,
        case: initialState.case,
      };

    default:
      return state;
  }
};

export default caseReducers;
